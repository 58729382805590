import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import Seo from '../../components/SEO';
import Theme from '../../../src/components/Theme';
import ContentfulText from '../../components/ContentfulText';
import GeneralLayout from '../../layouts/index';

import * as styles from '../../../src/components/Terms/terms.module.scss';

const SimplePage = ({ transition, data }) => {
  const { contentfulSimplePage, site } = data;

  return (
      <GeneralLayout>
        <Theme transition={transition}>
          <Seo
            siteData={site}
            seoData={contentfulSimplePage.seo}
            defaultTitle={`${contentfulSimplePage.title} - Emergence`}
            pathName={`/${contentfulSimplePage.slug}`}
          />
          <div className={styles.content}>
            <div className={styles.head}>
              <h2>{contentfulSimplePage.title}</h2>
              <strong>{contentfulSimplePage.subtitle}</strong>
            </div>

            <ContentfulText textObj={contentfulSimplePage.contentText} />
          </div>
        </Theme>
      </GeneralLayout>
  );
};

SimplePage.propTypes = {
  transition: PropTypes.object,
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

export default SimplePage;

export const pageQuery = graphql`
  query SimplePageQuery($id: String!) {
    contentfulSimplePage(id: { eq: $id }) {
      title
      subtitle
      slug
      contentText {
        childMarkdownRemark {
          html
        }
      }
      seo {
        customTitle
        metaDescription
        shareImage {
          file {
            url
          }
        }
      }
    }
    ...SiteQuery
  }
`;
